var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("vue-element-loading", {
        ref: "loader",
        attrs: { active: _vm.isLoading, "is-full-screen": true },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisible,
              expression: "isVisible",
            },
          ],
          staticClass: "login-wrapper auth-wrapper",
        },
        [
          _c("div", { staticClass: "login-box auth-box" }, [
            _c("h2", [_vm._v("Login")]),
            _c("form", [
              _c("div", { staticClass: "form-items" }, [
                _c("div", { staticClass: "user-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.username,
                        expression: "form.username",
                      },
                    ],
                    attrs: { type: "text", name: "", required: "" },
                    domProps: { value: _vm.form.username },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "username", $event.target.value)
                      },
                    },
                  }),
                  _c("label", [_vm._v("Username")]),
                ]),
                _c("div", { staticClass: "user-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.password,
                        expression: "form.password",
                      },
                    ],
                    attrs: { type: "password", name: "", required: "" },
                    domProps: { value: _vm.form.password },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "password", $event.target.value)
                      },
                    },
                  }),
                  _c("label", [_vm._v("Password")]),
                ]),
                _c("div", { staticClass: "btn-container" }, [
                  _c(
                    "a",
                    {
                      staticClass: "submit",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.login()
                        },
                      },
                    },
                    [_vm._v("\n              Submit\n            ")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "reset",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("\n              Reset\n            ")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }