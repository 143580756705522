import Vue from 'vue'

Vue.config.devtools = true
//import './pollyfills'

const config = VUE_APP_CONFIG_JSON;
//console.log('Config', config)
import configPlugin from './config/configPlugin'

Vue.use(configPlugin, config);

import VueLogger from 'vuejs-logger';
//const c = ()=>import('vue-lighthouse-viewer')

/*import VueBpmnModeler from "vue-bpmn-modeler";
import "vue-bpmn-modeler/lib/vue-bpmn-modeler.css";
Vue.use(VueBpmnModeler);*/

const isProduction = process.env.NODE_ENV === 'production';

const vueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, vueLoggerOptions);


/*import VueHead from 'vue-head'
Vue.use(VueHead)*/

import VueRouter from 'vue-router'
import Storage from 'vue-ls';

//import VueNotify from 'vue-notifyjs'
//import 'vue-notifyjs/themes/default.css'
// Vue.use(VueNotify, {type: 'primary', timeout: 2000})

import VueAWN from "vue-awesome-notifications"
//import 'vue-awesome-notifications/dist/styles/style.css';

Vue.use(VueAWN);


import axios from 'axios'
import VueAxios from 'vue-axios'

import VueAuthenticate from 'vue-dash-authenticate'
Vue.use(VueAuthenticate, VUE_APP_CONFIG_JSON.VueAuthenticate.options);
Vue.use(VueAxios, axios)

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history'
});

Vue.router = router;
Vue.use(VueRouter)



import App from './App.vue'
import SideBar from './app/UIComponents/SidebarPlugin'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import AuthComponents from 'vue-auth-components'

import sidebarLinks from './sidebarLinks'
import routes from './routes'

Vue.use(AuthComponents, { authOptions:  VUE_APP_CONFIG_JSON.VueAuthenticate.options, appConfig: VUE_APP_CONFIG_JSON.appConfig});

Vue.config.productionTip = false
Vue.use(Storage, VUE_APP_CONFIG_JSON.Storage.options);

Vue.use(GlobalComponents)
Vue.use(GlobalDirectives)


Vue.use(SideBar, {sidebarLinks: sidebarLinks})


/*import vueSchemaFormPlugin from "vue-json-schema-form"
import vueSchemaFormEditor from "vue-json-schema-form-editor"
import vueDashboardFrameworkPlugin from 'vue-dashboard-framework'
import vueDashboardFrameworkPluginApp from "vue-dashboard-framework-app-components"
import vueDashboardFrameworkIntegrations from "vuedf-integrations"


Vue.use(vueSchemaFormPlugin)
Vue.use(vueSchemaFormEditor);
Vue.use(vueDashboardFrameworkPlugin, {
  eventBus: VUE_APP_CONFIG_JSON.WSEventBusOptions
});
Vue.use(vueDashboardFrameworkPluginApp);
Vue.use(vueDashboardFrameworkIntegrations);*/


//Vue.use(FeedDashboardComponent, VUE_APP_CONFIG_JSON.FeedDashboardComponent.options);

/*
import metricDimensionComponents from "./components/MetricDimensionComponents";
Vue.use(metricDimensionComponents, {});
*/

const vueInstance = new Vue({
  el: '#app',
  router,
  render: h => h(App)
});
