var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "navbar navbar-expand-lg", class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _vm._t("default"),
          _c("CollapseTransition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showNavbar,
                    expression: "showNavbar",
                  },
                ],
                staticClass:
                  "collapse navbar-collapse justify-content-end show",
                class: _vm.navbarMenuClasses,
                attrs: { id: "navigation" },
              },
              [
                _c(
                  "ul",
                  { staticClass: "navbar-nav" },
                  [_vm._t("navbar-menu")],
                  2
                ),
              ]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }