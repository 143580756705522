<template>
  <div class="row">
    <div class="col-12">
      <div class="login-container card centered p-4">
        <login-component></login-component>
       <!-- <a href="" @click.prevent="authLogin()">Auth Login</a>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data(){
      return {
        context: 'oauth2 context',
        code: this.$route.query.code,
        type: this.$route.params.type,
      }
    }
  }
</script>

<style scoped>

  .login-container {
    max-width: 300px;
  }

</style>
