<template>
  <div class="row">
    <div class="col-12">
      <div class="register-container card centered p-4">
        <register-component></register-component>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Register"
  }
</script>

<style scoped>

  .register-container {
    max-width: 300px;
  }

</style>
