<template>
  <div class="auth-wrapper register-verify-wrapper">
    <div v-if="showUpdatePassword" class="password-verify-update auth-box">
      <form>
        <div class="user-box">
          <input type="password" v-model="form.password" name="" required="">
          <label>Password</label>
        </div>
        <div class="user-box">
          <input type="password" @keydown.enter="updatePassword()" v-model="form.confirmPassword" name="" required="">
          <label>Confirm Password</label>
        </div>
        <div class="btn-container">
          <a class="submit" href="#" @click="updatePassword()">
            Submit
          </a>
          <a class="reset" href="#" @click="reset()">
            Reset
          </a>
        </div>
      </form>
    </div>
    <div v-if="errorMessage" class="auth-box error-container">
      <h2><span>{{errorMessage}}</span></h2>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RegisterVerifyComponent",
    data(){
      return {
        errorMessage: null,
        showUpdatePassword: false,
        query: null,
        path: null,
        params: null,
        form: {
          password: '',
          confirmPassword: ''
        }
      }
    },
    created(){

      const that = this;
      const query =  this.$route.query;
      console.log('query', this.$route.query);
      console.log('path', this.$route.path);
      console.log('params', this.$route.params);

      this.query = query;
      this.path = this.$route.path;
      this.params = this.$route.params;

      this.verify(function(response){
        if (!response){
          that.errorMessage = 'Verification not completed. Please try again.';
        }else{
          console.log('Response after verify', response);
          if (response.data && response.data.userInApplications && response.data.userInApplications.length && response.data.userInApplications.length>1){
            that.redirect();
          }else {
            that.showUpdatePassword = true;
          }
        }
      });


    },
    methods:{
      reset() {
        this.form = {};
      },
      redirect(){
        const that = this;
        that.errorMessage = 'Redirecting to login...';
        console.log(this.query)
        setTimeout(function(){
          that.$router.push('/');// TODO - refactor backend to return "redirectUrl"
        },3000);
      },
      updatePassword() {
        //this.authenticate(this.form);
        const that = this;
        if (this.form.password!==this.form.confirmPassword){
          that.errorMessage = "Password doesn't match";
        }else{
          const body = {
            password: this.form.password,
            confirmPassword: this.form.confirmPassword,
            token: this.query.token
          }
          const url = this.$auth.options.providers.etl.registerUpdatePasswordUrl;
          this.$http.post(url, body)
          .then(function(response){
            console.log('Response after password update', response);
            that.redirect();
          })
          .catch(function(err){
            console.error(e);
            that.errorMessage = 'Cannot update password. Please try again';
          });
        }

      },
      verify(next){
        if (this.query && this.query.token){
          const url = this.$auth.options.providers.etl.registerVerifyUrl;
          const body = {
            token: this.query.token
          }
          const that = this;
          this.$http.post(url, body)
            .then(function(response){
              next(response);
              //that.$router.push('/');
            })
            .catch(function(e){
              console.error(e);
              if (e && e.response && e.response.data) {
                that.errorMessage = e.response.data.message;
              }else{
                next();
              }
            });
        }
      }
    }
  }
</script>

<style scoped>

</style>
