<template>
  <component class="dropdown"
             :is="tag"
             :class="[{show: isOpen}]"
             aria-haspopup="true"
             :aria-expanded="isOpen"

             v-click-outside="closeDropDown">
    <slot name="title" :is-open="isOpen">
      <a class="dropdown-toggle nav-link"
         @click.prevent="toggleDropDown"
         :class="{'no-caret': hideArrow}"
         data-toggle="dropdown">
        <i :class="icon"></i>
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul class="dropdown-menu"
        :x-placement="directionAttribute"
        :class="[{'dropdown-menu-right': position === 'right'}, {show: isOpen}]">
      <slot name="default">
        <li class="dropdown-item">
          <input type="checkbox" class="input-control">
          <span class="ml-1">Select All</span>
        </li>
        <li><hr /></li>
        <li class="dropdown-item"
            v-for="item in options">
            <input type="checkbox" :id="item.item" class="input-control" :value="item.item" v-model="selected"><span class="ml-1"
            :for="item.item">{{ item.name }}</span>
        </li>

      </slot>
    </ul>
  </component>

</template>

<script>
export default {
  name: "DropDownCheckboxes",
  props: {
    value: Array,
    direction: {
      type: String,
      default: 'down',
      description: 'Drop down menu direction (up|down)'
    },
    title: String,
    icon: String,
    position: {
      type: String,
      description: 'Drop down menu arrow position (left|right)'
    },
    hideArrow: {
      type: Boolean,
      description: 'Whether to hide drop down arrow'
    },
    tag: {
      type: String,
      default: 'li',
      description: 'Html tag of the dropdown'
    }
  },
  data() {
    return {
      isOpen: false,
      selected: [],
      options: [
        {
          item: 'A',
          name: 'Option A'
        },
        {
          item: 'B',
          name: 'Option B'
        },
        {
          item: 'C',
          name: 'Option C'
        },
        {
          item: 'D',
          name: 'Option D'
        }
      ]
    }
  },
  computed: {
    directionAttribute() {
      let baseDirection = '';
      if (this.direction === 'up') {
        baseDirection = 'top-start';
      } else if (this.direction === 'down') {
        baseDirection = 'bottom-start';
      }
      return baseDirection;
    }
  },
  methods: {
    returnFalse() {
      return false;
    },
    toggleDropDown() {
      this.isOpen = true;//!this.isOpen
      this.$emit('change', this.isOpen)
    },
    closeDropDown() {
      if (this.isOpen) {
        this.isOpen = false
        this.$emit('change', this.isOpen)
      }
    }
  }
}
</script>

<style scoped>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

</style>
