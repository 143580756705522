<template>
  <div class="login-container">
    <vue-element-loading ref="loader" :active="isLoading" :is-full-screen="true"></vue-element-loading>
    <div v-show="isVisible" class="login-wrapper auth-wrapper">
      <div class="login-box auth-box">
        <h2>Login</h2>
        <form>
          <div class="form-items">
            <div class="user-box">
              <input type="text" v-model="form.username" name="" required="">
              <label>Username</label>
            </div>
            <div class="user-box">
              <input @keydown.enter="login()" type="password" v-model="form.password" name="" required="">
              <label>Password</label>
            </div>
            <div class="btn-container">
              <a class="submit" href="#" @click="login()">
                Submit
              </a>
              <a class="reset" href="#" @click="reset()">
                Reset
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'

export default {
  name: "LoginComponent",
  components: {VueElementLoading},
  data() {
    return {
      isVisible: true,
      form: {
        username: '',
        password: ''
      },
      errorMessage: null,
      isLoading: false
    }
  },
  methods: {
    reset() {
      this.form = {};
    },
    login() {
      // handle login here
      this.authenticate(this.form);
    },
    beforeRouteLeave(to, from, next) {
      this.isLoading = false;
      next();
    },
    authenticate: function (loginModel) {
      const that = this;
      this.isLoading = true;
      loginModel.clientId = this.$auth.options.providers.etl.clientId;
      this.$auth.login(loginModel).then(function (response) {
        that.isVisible = false;
        if (that.$auth.isAuthenticated()) {
          that.$auth.getUser('etl').then(function(user){

            that.$userService.setCurrentUser(user);

            let redirect = that.$route.query.redirect;
            if (!redirect)
              redirect = '/';
            that.$router.push(redirect);

          });


        } else {
          that.isLoading = false;
          that.isVisible = true;
          that.errorMessage = 'Not authenticated';
        }
      }).catch(function (e) {
        that.isLoading = false;
        that.isVisible = true;
        that.errorMessage = e;
      });

    }
  }
}
</script>

<style scoped>

</style>
