<template>
  <div class="auth-wrapper register-wrapper">
    <schema-form :schema="schema" :form="form" v-model="model" @validationResult="onValidationResult"></schema-form>
    <div v-if="errorMessage" class="text-danger">
      {{errorMessage}}
    </div>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    name: "RegisterComponent",
    props: {
      applicationTenantName: {
        type: String
      },
      tenantName: {
        type: String
      },
      clientId: {
        type: String
      }
    },
    data() {
      return {
        errorMessage: null,
        schema: {
          type: 'object',
          properties: {
            username: {
              type: 'string',
              title: 'User Name',
              description: 'Enter UserName'
            },
            name: {
              type: 'string',
              title: 'Name',
              description: 'Enter Your Name'
            },
            lastName: {
              type: 'string',
              title: 'Last Name',
              description: 'Enter Last Name'
            },
            password: {
              type: 'string',
              title: 'Password',
              description: 'Enter password'
            },
            confirmpassword: {
              type: 'string',
              title: 'Confirm password',
              description: 'Enter password again'
            }
          }
        },
        form: [
          'username', 'name', 'lastName',
          {
            key: 'password',
            type: 'password'
          },
          {
            key: 'confirmpassword',
            type: 'password'
          },
          {
            type: 'actions',
            items: [

              {
                type: "submit",
                title: "Register"
              },
              {
                type: "reset",
                title: "Clear"
              }
            ]
          }
        ],
        model: {}
      }
    },
    methods: {
      onValidationResult: function (event) {
        if (event.valid) {
          this.errorMessage = null;
          const that = this;

          const registerModel = cloneDeep(this.model);
          registerModel.clientId = this.$auth.options.providers.etl.clientId; //this.clientId;
          registerModel.tenantName = this.$auth.options.providers.etl.tenantName;

          this.$auth.register(registerModel).then(function (response) {
            that.isAuthenticated = that.$auth.isAuthenticated();
            that.response = response
          }).catch(function(e){
            that.errorMessage = e;
          });
        } else {
          this.errorMessage = JSON.parse(event);
        }
      }
    }
  }
</script>

<style scoped>

</style>
