import {Dropdown,DropDownCheckboxes} from 'src/app/UIComponents';

const GlobalComponents = {
  install(Vue) {
    Vue.component(Dropdown.name, Dropdown);
    Vue.component(DropDownCheckboxes.name, DropDownCheckboxes);
  }
}

export default GlobalComponents
