import LoginComponent from './LoginComponent'
import RegisterComponent from './RegisterComponent'
import RegisterVerifyComponent from './RegisterVerifyComponent'
import UserInfoComponent from './UserInfoComponent'

export let UserService = {
  setClientId: function(clientId){
    this.clientId = clientId;
  },
  getCurrentApplicationUser: function(){
    if (this.user && this.user.userInApplications && this.clientId){
      const that = this;
      const userInApplication = this.user.userInApplications.filter(r=>{
        return r.clientId === that.clientId
      })
      if (userInApplication && userInApplication.length){
        return userInApplication[0];
      }
    }
  },
  getCurrentUser: function () {
    return this.user;
  },
  setCurrentUser: function (user) {
    this.user = user;
  }
}

const plugin = {
  notifyMessage: function (vueApp, message) {
    /*if (vueApp.$notify) {
      vueApp.$notify(
        {
          message: message,
          horizontalAlign: 'right',
          verticalAlign:  'bottom',
          type: 'danger',
          timeout: 5000,
          title: 'Response message'
        }
      );
    }*/
    if (vueApp.$awn){
      vueApp.$awn.warning(message, {
        position: 'bottom-right',
        animationDuration: 5000
      });
    }
  },
    responseInterceptors: function (Vue) {
      const that = this;
      Vue.axios.interceptors.response.use(undefined, function (err) {
        if (!err || !err.response) {
          return err;
        }
        const app = Vue.router.app;
        console.log('Error in interceptor', err);

        if (err.response.status === 401) {
          that.notifyMessage(app, err.response.statusText ? err.response.statusText : 'You are not authorized');
          app.$auth.logout();
        } else if (err.response.status === 400) {
          Vue.router.push('/');
        } else if (err.response.status >= 500) {
          that.notifyMessage(app, err.response.statusText ? err.response.statusText : 'Server Error');
        }
        return Promise.reject(err);
      });
    },
    initRouter: function (router) {
      return router.beforeEach((to, from, next) => {
        //  console.log('Before each in Auth Component', to, from ,next);
        if (to.matched.some(record => record.meta.requiresAuth)) {
          const app = router.app;
          // this route requires auth, check if logged in
          // if not, redirect to login page.
          if (!app.$auth.isAuthenticated()) {
            next({
              path: '/login',
              query: {redirect: to.fullPath}
            })
          } else {
            next()
          }
        } else {
          next() // make sure to always call next()!
        }
      })
    },
  install(Vue, options) {

   // console.log('VueAuth component options', options);

    Vue.component(LoginComponent.name, LoginComponent)
    Vue.component(RegisterComponent.name, RegisterComponent);
    Vue.component(RegisterVerifyComponent.name, RegisterVerifyComponent);
    Vue.component(UserInfoComponent.name, UserInfoComponent);

    Vue.routerBeforeEachHook = this.initRouter(Vue.router);
    this.responseInterceptors(Vue);

    Vue.userService = UserService;
    Vue.userService.setClientId(options.authOptions.providers.etl.clientId);

    Object.defineProperty(Vue.prototype, '$userService', {
      get() {
        return UserService;
      }
    })
  }
};

export default plugin
