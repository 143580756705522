<template>
  <div class="user-info-wrapper">
    <drop-down icon="fas fa-user" :tag="dropdownTag"
               :position="position"
               direction="none"
               :class="dropdownClass"
               class="dropdown">
      <a slot="title"
         slot-scope="{isOpen}"
         class="dropdown-toggle"
         :class="dropdownLinkClass"
         data-toggle="dropdown"
         aria-haspopup="true"
         :aria-expanded="isOpen">
        <p class="mr-1">{{fullName}}</p>
        <i class="fas fa-user"></i>
      </a>
      <slot></slot>
      <a class="" href="#" v-if="hasSeparator">
        <hr/>
      </a>
      <a class="dropdown-item" href="" @click="logout()">Logout</a>
    </drop-down>
  </div>
</template>

<script>
  export default {
    name: "UserInfoComponent",
    props: {
      position:{
        type: String,
        default: function(){
          return 'right';
        }
      },
      dropdownTag: {
        type: String,
        default: function(){
          return 'div'
        }
      },
      dropdownClass:{
        type: String,
        default: function(){
          return 'nav-item'
        }
      },
      dropdownLinkClass:{
        type: String,
        default: function(){
          return 'nav-link';
        }
      }
    },
    data() {
      return {
        user: {

        },
        context: 'oauth2 context',
        code: this.$route.query.code,
        type: this.$route.params.type,
        redirect: '/'
      }
    },
    mounted() {
      this.code = this.$route.query.code;
      this.type = this.$route.params.type;
      this.redirect = this.$route.query.redirect;

      if (this.code) {
        const that = this;
       // console.log('code in topnavbar ', this.code);
        this.$auth.oauth2({
          code: true,
          provider: this.type,
          params: {
            code: this.code,
          },
          success: function (res) {
            that.$router.push(that.redirect);
           // console.log('success ' + this.context);
          },
          error: function (res) {
            console.error('error ' + this.context);
          }
        });
      }
    },
    created: function () {
      const that = this;
      if (this.$auth.isAuthenticated()) {
        this.resolveUser();
      }
    },
    computed: {
      fullName: function () {
        return this.user.name ? (this.user.name + ' ' + this.user.lastName) : this.user.username;
      },
      hasSeparator: function(){
        const res  = this.$slots.default;
        return !res===false;
      }
    },
    methods: {
      logout: function () {
        const that = this;
        this.$auth.logout(null, 'etl')
          .then(()=>{
            that.userService.setCurrentUser(null);
          })
          .catch(err=>{
            that.userService.setCurrentUser(null);
          });
      },
      resolveUser: function () {
        const that = this;
        this.$nextTick(function () {
          that.$auth.getUser('etl')
            .then(function (user) {
        //      console.log('UserInfoComponent resolved user', user);
              that.user = user;
              that.$userService.setCurrentUser(user);
            }).catch(function (error) {
            console.error(error);
          });
        });
      }
    }
  }
</script>

<style scoped>
  .user-info-wrapper .dropdown hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }

</style>
