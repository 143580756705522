var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dashboard-main-container container-fluid1",
      class: [{ "nav-open": _vm.$sidebar.showSidebar }],
    },
    [
      _c("router-view", { attrs: { name: "header" } }),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "fade" } },
        [_c("router-view")],
        1
      ),
      _c("router-view", { attrs: { name: "debuglog" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }