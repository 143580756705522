export default [
  {
    name: 'Form',
    icon: 'fas fa-home',
    path: '/form'
  },
  {
    name: 'Form Editor',
    icon: 'fas fa-edit',
    path: '/formeditor'
  },
  {
    name: 'VueDf',
    icon: 'fas fa-columns',
    path: '/vuedf'
  },
  {
    name: 'Chart Builder',
    icon: 'fas fa-chart-line',
    path: '/chartbuilder'
  },
  {
    name: 'Visualisation Designer',
    icon: 'fas fa-cog',
    path: '/visualisation-designer'
  },
  {
    name: 'Metric/Dimension Builder',
    icon: 'fas fa-memory',
    path: '/md-designer'
  },
  {
    name: 'Diagram Editor',
    icon: 'fas fa-edit',
    path: '/diagrameditor'
  },
  {
    name: 'Flow Generic Editor',
    icon: 'fas fa-edit',
    path: '/flowgeneric'
  },
  {
    name: 'Bpmn Modeler Editor',
    icon: 'fas fa-edit',
    path: '/bpmneditormodeler'
  },
  {
    name: 'Bpmn Editor',
    icon: 'fas fa-cog',
    path: '/bpmneditor'
  },
  {
    name: 'VueDf View',
    icon: 'fas fa-eye',
    path: '/df/vueDfViewDashboard'
  },
  {
    name: 'Applications View',
    icon: 'fas fa-folder',
    path: '/applications'
  },
  {
    name: 'Various View',
    icon: 'fas fa-folder',
    path: '/various'
  },
  /*{
    name: 'FeedDf',
    icon: 'fas fa-rss',
    path: '/feed'
  },*/
  {
    name: 'Scraper',
    icon: 'fas fa-spider',
    path: '/scraper'
  }
]
