const Home = () => import( './views/Home.vue')
const DashboardLayout = () => import('./app/Layout/DashboardLayout')
const DashboardViewerLayout = () => import( './app/Layout/DashboardViewerLayout')
const DashboardLoginLayout = () => import( './app/Layout/DashboardLoginLayout')
const DashboardSingleLayout = () => import( './app/Layout/DashboardSingleLayout')

import Register from "./views/Register";
import Login from './views/Login'
import RegisterVerify from "./views/RegisterVerify";

const ApplicationsView = () => import ("./views/ApplicationsView");
const Scraper = () => import("./views/Scraper");

const VariousView = () => import('./views/VariousView');
const VueDf = () => import('./views/VueDf')
const VueDfReadOnly = () => import('./views/VueDfReadOnly')
const VueSingleDashboard = () => import('./views/VueSingleDashboard')
const Formeditor = () => import('./views/Formeditor')
const FeedDashboard = () => import('./views/FeedDashboard')
const ChartBuilder = () => import('./views/ChartBuilder')
const VisualisationDesignerView = () => import('./views/VisualisationDesignerView')
const MetricDimensionBuilderView = () => import('./views/MetricDimensionBuilderView')
const DiagramEditor = () => import('./views/DiagramEditor')
const BpmnEditorModelerView = () => import('./views/BpmnEditorModelerView')
const BpmnEditorView = () => import('./views/BpmnEditorView')

const FlowGenericDiagram = () => import('./views/FlowGenericDiagram')

//import DebugEventLog from "vue-dashboard-framework/src/components/vuedf/components/DebugEventLog";

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: 'form',
    meta: {requiresAuth: true},
    children: [
      {
        path: 'form',
        name: 'home',
        meta: {requiresAuth: true},
        component: Home
      }
    ]
  },
  {
    path: '/formeditor',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'formeditor',
        meta: {requiresAuth: true},
        component: Formeditor
      }
    ]
  },
  {
    path: '/vuedf',
    components: {
      default: DashboardLayout
    },
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'vuedf',
        meta: {requiresAuth: true},
        components: {
          default: VueDf
        }
      }
    ]
  },
  {
    path: '/chartbuilder',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'chartbuilder',
        meta: {requiresAuth: true},
        component: ChartBuilder
      }
    ]
  },
  {
    path: '/visualisation-designer',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'visualisation-designer',
        meta: {requiresAuth: true},
        component: VisualisationDesignerView
      }
    ]
  },
  {
    path: '/diagrameditor',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'diagrameditor',
        meta: {requiresAuth: true},
        component: DiagramEditor
      }
    ]
  },
  {
    path: '/bpmneditor',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'bpmneditor',
        meta: {requiresAuth: true},
        component: BpmnEditorView
      }
    ]
  },
  {
    path: '/flowgeneric',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'flowgeneric',
        meta: {requiresAuth: true},
        component: FlowGenericDiagram
      }
    ]
  },

  {
    path: '/bpmneditormodeler',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'bpmneditormodeler',
        meta: {requiresAuth: true},
        component: BpmnEditorModelerView
      }
    ]
  },

  {
    path: '/md-designer',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'md-designer',
        meta: {requiresAuth: true},
        component: MetricDimensionBuilderView
      }
    ]
  },
  {
    path: '/feed',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'feedDashboard',
        meta: {requiresAuth: true},
        component: FeedDashboard
      }
    ]
  },
  {
    path: '/scraper',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'scrapper',
        meta: {requiresAuth: true},
        component: Scraper
      }
    ]
  },
  {
    path: '/df',
    component: DashboardViewerLayout,
    redirect: '',
    meta: {requiresAuth: false},
    children: [
      {
        path: '',
        name: 'df',
        meta: {requiresAuth: false},
        component: VueDfReadOnly
      },
      {
        path: ':id',
        name: 'df_id',
        meta: {requiresAuth: false},
        component: VueDfReadOnly
      }
    ]
  },
  {
    path: '/dfs',
    component: DashboardSingleLayout,
    redirect: '',
    meta: {requiresAuth: false},
    children: [
      {
        path: '',
        name: 'dfs',
        meta: {requiresAuth: false},
        component: VueSingleDashboard,
        children: [
          {
            path: '/dfs/proba',
            name: 'neka proba',
            meta: {requiresAuth: false},
            component: {template: '<h2 class="pull-right">Evo neka proba</h2>'}
          }
        ]
      }
    ]
  },
  {
    path: '/applications',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'applications',
        meta: {requiresAuth: true},
        component: ApplicationsView
      }
    ]
  },
  {

    path: '/various',
    component: DashboardLayout,
    redirect: '',
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'various',
        meta: {requiresAuth: true},
        component: VariousView
      }
    ]
  },
  {
    path: '/login',
    component: DashboardLoginLayout,
    redirect: '/form',
    meta: {requiresAuth: false},
    children: [
      {
        path: '',
        name: 'login',
        meta: {requiresAuth: false},
        component: Login
      }
    ]
  },
  {

    path: '/register',
    component: DashboardLoginLayout,
    redirect: '',
    meta: {requiresAuth: false},
    children: [
      {
        path: '',
        name: 'register',
        meta: {requiresAuth: false},
        component: Register
      }
    ]
  },
  {
    path: '/register/verify',
    component: DashboardLoginLayout,
    redirect: '',
    meta: {requiresAuth: false},
    children: [
      {
        path: '',
        name: 'register_verify',
        meta: {requiresAuth: false},
        component: RegisterVerify
      }
    ]
  }
];


export default routes;
