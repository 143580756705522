<template>
  <div :class="[{'nav-open': $sidebar.showSidebar}]" class="dashboard-main-container container-fluid1">
    <router-view name="header"></router-view>
    <transition mode="out-in"
                name="fade">

      <router-view>
      </router-view>
    </transition>
    <router-view name="debuglog"></router-view>
<!--    <portal-target name="portalDebugLog"></portal-target>-->
  </div>
</template>

<script>
import {Portal, PortalTarget} from 'portal-vue'
export default {
  name: 'app',
  components: {Portal, PortalTarget},
  data(){
    return {
      updateNotify: true,
      notificationData:[]
    }
  },
}

</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
