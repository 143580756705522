var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-wrapper register-verify-wrapper" }, [
    _vm.showUpdatePassword
      ? _c("div", { staticClass: "password-verify-update auth-box" }, [
          _c("form", [
            _c("div", { staticClass: "user-box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password",
                  },
                ],
                attrs: { type: "password", name: "", required: "" },
                domProps: { value: _vm.form.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "password", $event.target.value)
                  },
                },
              }),
              _c("label", [_vm._v("Password")]),
            ]),
            _c("div", { staticClass: "user-box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.confirmPassword,
                    expression: "form.confirmPassword",
                  },
                ],
                attrs: { type: "password", name: "", required: "" },
                domProps: { value: _vm.form.confirmPassword },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.updatePassword()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "confirmPassword", $event.target.value)
                  },
                },
              }),
              _c("label", [_vm._v("Confirm Password")]),
            ]),
            _c("div", { staticClass: "btn-container" }, [
              _c(
                "a",
                {
                  staticClass: "submit",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.updatePassword()
                    },
                  },
                },
                [_vm._v("\n          Submit\n        ")]
              ),
              _c(
                "a",
                {
                  staticClass: "reset",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.reset()
                    },
                  },
                },
                [_vm._v("\n          Reset\n        ")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.errorMessage
      ? _c("div", { staticClass: "auth-box error-container" }, [
          _c("h2", [_c("span", [_vm._v(_vm._s(_vm.errorMessage))])]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }