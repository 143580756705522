var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-wrapper register-wrapper" },
    [
      _c("schema-form", {
        attrs: { schema: _vm.schema, form: _vm.form },
        on: { validationResult: _vm.onValidationResult },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _vm.errorMessage
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }