const configPlugin = {
  install(Vue, options){

    Vue.appConfig = options;

    Object.defineProperty(Vue.prototype, '$appConfig', {
      get() {
        return options;
      }
    })
  }
}

export default configPlugin;
