var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-info-wrapper" },
    [
      _c(
        "drop-down",
        {
          staticClass: "dropdown",
          class: _vm.dropdownClass,
          attrs: {
            icon: "fas fa-user",
            tag: _vm.dropdownTag,
            position: _vm.position,
            direction: "none",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function ({ isOpen }) {
                return _c(
                  "a",
                  {
                    staticClass: "dropdown-toggle",
                    class: _vm.dropdownLinkClass,
                    attrs: {
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": isOpen,
                    },
                  },
                  [
                    _c("p", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.fullName)),
                    ]),
                    _c("i", { staticClass: "fas fa-user" }),
                  ]
                )
              },
            },
          ]),
        },
        [
          _vm._t("default"),
          _vm.hasSeparator
            ? _c("a", { attrs: { href: "#" } }, [_c("hr")])
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            },
            [_vm._v("Logout")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }