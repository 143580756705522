import {directive} from 'vue-clickaway'
import Clipboard from 'vue-dashboard-framework/src/components/vuedf/copyToClipboard'

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', directive)
    Vue.use(Clipboard);
  }
}

export default GlobalDirectives
